import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import Layout from "../components/common/layout";
import PageTitle from "../components/common/page-title";
import Pagination from "../components/common/pagination";

export const query = graphql`
  query GalleryQuery($skip: Int!, $limit: Int!) {
    galleries: allSanityGallery(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: _createdAt }
    ) {
      edges {
        node {
          id
          mainImage {
            asset {
              id
            }
            caption
            alt
          }
        }
      }
    }
  }
`;

const GalleryPage = (props) => {
  const { data } = props;

  const galleryNodes = (data || {}).galleries
    ? mapEdgesToNodes(data.galleries)
    : [];

  return (
    <Layout>
      <PageTitle title="Galeri Desa" />
      <div className="min-vh-100 mw-100">
        <div className="mw9 center pa2 mt4 mb4 ph4-ns">
          {galleryNodes && galleryNodes.length > 0 && (
            <div>
              {galleryNodes.map((node, index) => (
                <section
                  key={index}
                  className="fl w-100 w-50-m  w-25-ns pa2-ns"
                >
                  <a
                    href="https://geo.itunes.apple.com/us/album/blonde/id1146195596?at=1l3vqFJ&mt=1&app=music"
                    className="db link dim tc"
                  >
                    <Img
                      className="w-100 db outline black-10 aspect-ratio aspect-ratio--1x1"
                      fluid={node.mainImage.asset.fluid}
                      alt={node.mainImage.alt}
                    />
                    <dl className="mt3 f5 i lh-copy">
                      <dt className="clip">Caption</dt>
                      <dd className="ml0 black truncate w-100">
                        {node.mainImage.caption}
                      </dd>
                    </dl>
                  </a>
                </section>
              ))}
            </div>
          )}

          {/* <article className='fl w-100 w-50-m  w-25-ns pa2-ns'>
            <div className='aspect-ratio aspect-ratio--1x1'>
              <Img
                className='aspect-ratio aspect-ratio--1x1'
                fluid={galleryNodes[1].mainImage.asset.fluid}
                alt={galleryNodes[1].mainImage.alt} />
            </div>
            <a href='#0' className='ph2 ph0-ns pb3 link db'>
              <h3 className='f5 f4-ns mb0 black-90'>{galleryNodes[1].mainImage.caption}</h3>
              <h3 className='f6 f5 fw4 mt2 black-60'>Subtitle of piece</h3>
            </a>
          </article> */}

          {/* <CompImage
            image={galleryNodes[0].mainImage} /> */}
        </div>
      </div>

      <div className="w-100 fl">
        <Pagination
          humanPageNumber={props.pageContext.humanPageNumber}
          numberOfPages={props.pageContext.numberOfPages}
          previousPagePath={props.pageContext.previousPagePath}
          nextPagePath={props.pageContext.nextPagePath}
          paginationPath={props.pageContext.paginationPath}
        />
      </div>
    </Layout>
  );
};

export default GalleryPage;
